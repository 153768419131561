import { FillProperty } from 'csstype'
import React from 'react'
import { colors } from '../../../__styling/settings/colors'

export interface PerthNowSouthernLogoProps {
    className?: string
    width?: number
    height?: number
    fill?: FillProperty
    title?: string
}

const PerthNowSouthernLogo: React.FC<PerthNowSouthernLogoProps> = ({
    className,
    title,
    width = 130,
    height = 40,
    fill = colors.black,
}) => {
    return (
        <svg
            width={width}
            height={height}
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 132 50"
            preserveAspectRatio="xMinYMid meet"
        >
            <title>{title || 'Perth Now Melville'}</title>
            <path
                d="M7.40618 49.1043C13.2023 49.1043 16.2614 46.5045 16.2614 41.7543C16.2614 37.2287 14.0717 35.656 10.1432 34.9178L8.4688 34.5968C6.50456 34.2116 5.86054 33.7623 5.86054 32.5747C5.86054 31.2909 6.89096 30.6811 8.88741 30.6811C11.4313 30.6811 13.3633 31.1946 14.909 31.7402V27.2467C13.6209 26.6369 11.8499 26.0913 8.3078 26.0913C2.64046 26.0913 0 28.8516 0 33.0562C0 37.7101 2.18965 39.2829 5.73174 39.9569L7.40618 40.2779C9.56363 40.6951 10.1432 41.1124 10.1432 42.3962C10.1432 43.7443 9.17722 44.4825 6.66556 44.4825C4.0895 44.4825 2.02865 43.9368 0.0644015 43.2307V47.7884C1.67444 48.6229 4.2827 49.1043 7.40618 49.1043Z"
                fill="black"
            />
            <path
                d="M26.3002 49.0402C31.2913 49.0402 35.0588 46.1515 35.0588 40.2779V39.8606C35.0588 34.3079 31.9998 31.323 26.3002 31.323C21.3091 31.323 17.5416 34.2116 17.5416 40.0532V40.4704C17.5416 45.9268 20.6007 49.0402 26.3002 49.0402ZM26.3324 45.1565C24.5936 45.1565 23.3378 44.3541 23.3378 40.4062V39.7643C23.3378 36.2979 24.4004 35.1745 26.268 35.1745C28.0069 35.1745 29.2627 35.9128 29.2627 39.9569V40.5667C29.2627 44.0652 28.1679 45.1565 26.3324 45.1565Z"
                fill="black"
            />
            <path
                d="M42.0152 49.0402C44.1727 49.0402 45.6861 48.3661 47.1995 47.2107H47.3605L47.7469 48.7192H52.7059V31.6439H47.0707V44.1294C46.3623 44.4504 45.4929 44.7713 44.4947 44.7713C43.0134 44.7713 42.4338 44.2578 42.4338 42.8777V31.6439H36.7987V44.0652C36.7987 47.5637 38.7307 49.0402 42.0152 49.0402Z"
                fill="black"
            />
            <path
                d="M61.8242 48.976C63.6275 48.976 64.9155 48.6229 65.5917 48.2698V44.643C65.1409 44.7392 64.4969 44.8355 63.9173 44.8355C62.5326 44.8355 62.0174 44.4504 62.0174 43.2628V35.5597H65.4951V31.6439H62.0174V27.5998H56.3501V31.6439H54.2248V35.5597H56.3501V44.0652C56.3501 47.8205 58.3787 48.976 61.8242 48.976Z"
                fill="black"
            />
            <path
                d="M67.4526 48.7192H73.0877V36.2979C73.7961 36.009 74.8587 35.7202 75.7604 35.7202C77.3382 35.7202 77.9178 36.2016 77.9178 37.5497V48.7192H83.5529V36.009C83.5529 32.6389 81.6853 31.323 78.4974 31.323C76.34 31.323 74.5689 31.9649 73.2487 33.0241H73.0877V25H67.4526V48.7192Z"
                fill="black"
            />
            <path
                d="M94.342 49.0402C97.4976 49.0402 99.6229 48.2698 100.911 47.4995V44.001C99.5907 44.5467 97.8196 45.0281 95.791 45.0281C92.8929 45.0281 91.3795 44.2899 91.0253 41.6259H101.619V40.1495C101.619 34.5326 99.4619 31.323 93.7302 31.323C88.578 31.323 85.2613 34.6289 85.2613 39.9248V40.342C85.2613 45.991 88.256 49.0402 94.342 49.0402ZM93.7302 35.0462C95.3724 35.0462 96.1774 35.9449 96.1774 38.4484H90.9931C91.2507 35.7844 92.4421 35.0462 93.7302 35.0462Z"
                fill="black"
            />
            <path
                d="M103.419 48.7192H109.054V37.1645C110.02 36.6831 111.309 36.3942 112.822 36.3942C113.305 36.3942 113.917 36.4905 114.271 36.5547V31.6118C114.078 31.5156 113.724 31.4514 113.273 31.4514C111.47 31.4514 109.924 32.3822 109.022 33.6981H108.861V31.6439H103.419V48.7192Z"
                fill="black"
            />
            <path
                d="M115.9 48.7192H121.535V36.2979C122.243 36.009 123.306 35.7202 124.207 35.7202C125.785 35.7202 126.365 36.2016 126.365 37.5497V48.7192H132V36.009C132 32.6389 130.132 31.323 126.944 31.323C124.787 31.323 122.984 31.9649 121.503 33.1525H121.342V31.6439H115.9V48.7192Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M67.3252 6.7494V16.1452C67.3252 19.8732 70.3918 22.8949 74.1714 22.8949H125.071C128.852 22.8949 131.917 19.8732 131.917 16.1452V6.7494C131.917 3.02272 128.852 0 125.071 0H74.1714C70.3918 0 67.3252 3.02272 67.3252 6.7494Z"
                fill="#D42626"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M103.734 4.37622H107.607L110.705 14.2447H110.764L113.654 4.37622H117.527L120.624 14.2447H120.683L123.662 4.37622H127.266L122.411 18.4746H118.866L115.47 8.84081H115.411L112.403 18.4746H108.709L103.734 4.37622"
                fill="#FFFFFE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M96.4497 15.694C99.071 15.694 100.65 13.6683 100.65 11.4652C100.65 9.26236 99.071 7.23568 96.4497 7.23568C93.8284 7.23568 92.2497 9.26236 92.2497 11.4652C92.2497 13.6683 93.8284 15.694 96.4497 15.694M96.45 4.06372C100.769 4.06372 104.225 7.03008 104.225 11.4652C104.225 15.8997 100.769 18.8673 96.45 18.8673C92.1309 18.8673 88.6758 15.8997 88.6758 11.4652C88.6758 7.03008 92.1309 4.06372 96.45 4.06372"
                fill="#FFFFFE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M74.0923 4.41634H77.4883V6.67778H77.548C78.2029 5.26826 79.5434 4.06372 81.9266 4.06372C85.7692 4.06372 87.1396 6.73671 87.1396 9.61467V18.5147H83.5653V11.3774C83.5653 9.82028 83.4461 7.23569 80.9437 7.23569C78.5907 7.23569 77.6669 8.93953 77.6669 10.8192V18.5147H74.0923V4.41634"
                fill="#FFFFFE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M64.5847 11.4468C64.5657 6.8661 62.7738 4.23139 58.3649 4.23139C56.9007 4.23139 55.6062 4.60982 54.3906 5.6124V0.283447H50.9512V18.5972H54.3906V10.8679C54.3906 9.22943 55.2608 7.53491 58.1298 7.53491C59.9598 7.53491 61.134 8.7636 61.134 10.3587C61.1495 10.7149 61.1524 11.0411 61.1524 11.3027V18.5972H64.5912V11.4629L64.5847 11.4468"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M49.0242 15.1994V15.1911L49.0097 15.1994C47.4429 15.1208 45.9324 13.9723 45.4228 12.7265C45.3076 12.4446 45.2417 11.9788 45.2417 11.4449V7.43773H49.0242V4.54177H45.2417V0.593506H41.8022V10.7454C41.8022 11.2216 41.8495 12.2245 41.94 12.6752C42.6058 16.0028 45.611 18.5133 49.2163 18.5133V15.2082C49.1519 15.2082 49.0886 15.2019 49.0242 15.1994"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39.68 4.18715C39.5462 4.171 39.4113 4.15991 39.2716 4.15991L39.2066 4.16403C39.1778 4.16403 39.1497 4.15991 39.1203 4.15991C37.6563 4.15991 36.0534 4.50255 35.145 5.79141V4.50065H31.7065V18.5257H35.145V11.2844L35.1463 11.2353C35.1463 9.28651 36.3095 7.46375 39.1203 7.46375C39.6128 7.46375 40.0826 7.554 40.5189 7.71234V4.29291C40.2443 4.24161 39.9643 4.20931 39.68 4.18715"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.1898 7.46407C24.915 7.46407 26.3838 8.52556 26.9558 10.0136H19.4231C19.9958 8.52556 21.4633 7.46407 23.1898 7.46407M26.5277 13.5837C25.8049 14.6294 24.58 15.3178 23.1894 15.3178C21.3637 15.3178 19.8244 14.1325 19.3306 12.5096H27.047L27.046 12.5121H30.5417C30.5799 12.1362 30.6025 11.7724 30.6025 11.3914C30.6025 7.39725 27.2837 4.15991 23.1885 4.15991C19.0942 4.15991 15.7744 7.39725 15.7744 11.3914C15.7744 15.3856 19.0942 18.6226 23.1885 18.6226C25.6393 18.6226 27.8118 17.4636 29.1616 15.6766L26.5277 13.5837"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.43077 15.3175C5.20691 15.3175 3.40441 13.5597 3.40441 11.3915C3.40441 9.22225 5.20691 7.46346 7.43077 7.46346C9.21225 7.46346 10.7195 8.59334 11.2508 10.1561C11.353 10.5298 11.4064 10.9199 11.4064 11.3161C11.4064 12.1546 11.1684 12.9634 10.7286 13.6401C10.0003 14.6538 8.79607 15.3175 7.43077 15.3175M7.43022 4.15991C3.33595 4.15991 0.0164922 7.39693 0.0164922 11.3914C0.0164922 11.4259 0.0216662 11.4586 0.0216662 11.4921L0 11.5254V22.6339H3.43943V17.4779C4.59259 18.1992 5.9595 18.6223 7.43022 18.6223C11.5251 18.6223 14.8443 15.385 14.8443 11.3914C14.8443 7.39693 11.5251 4.15991 7.43022 4.15991"
                fill="black"
            />
        </svg>
    )
}

export default PerthNowSouthernLogo
